import ErrorGoHome from '@anm/components/errors/ErrorGoHome';
import getError from '@anm/data/errors';
import { FC } from 'react';

import MetaHead from '../components/MetaHead';
import Layout from '../components/layouts/Layout';
import vtConfig from '../config';

export type FourOhFourProps = {};

const { wave } = vtConfig().urls;

const FourOhFour: FC<FourOhFourProps> = () => (
  <>
    <MetaHead title="Error" />
    <Layout navigationVariant="white">
      <ErrorGoHome {...getError(404)} button={{ href: wave }} />
    </Layout>
  </>
);

export default FourOhFour;
