import TitleBlock from '@anm/components/TitleBlock';
import { centeredInFullHeight, media } from '@anm/styles/helpers';
import styled from 'styled-components';

const containerAdaptiveStyles = media.tablet`
  padding-left: 20px;
  padding-right: 20px;
`;

const Container = styled.div`
  padding-left: 30px;
  padding-right: 30px;
  ${containerAdaptiveStyles};
`;

const ErrorWrapperOnMobiles = media.tablet`
  align-items: center;
  > div {
    margin: 24px 0 0;
    text-align: center;
  }
  img {
    max-width: calc(100% - 100px);
    margin-top: 0;
  }
`;

const TitleBlockErrorOnMobiles = media.tablet`
  text-align: center;
`;

const ErrorWrapper = styled(Container)`
  ${centeredInFullHeight()};
  flex-wrap: wrap-reverse;
  > div {
    max-width: 370px;
    margin-right: 47px;
  }
  > * {
    margin-top: -50px;
  }
  ${ErrorWrapperOnMobiles};
`;

export const TitleBlockError = styled(TitleBlock)`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    word-break: break-word;
  }
  margin-bottom: 30px;
  ${TitleBlockErrorOnMobiles};
`;

export default ErrorWrapper;
