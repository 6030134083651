import Button, { ButtonProps } from '@anm/components/buttons/Button';
import { FC } from 'react';
import { ErrorGoHomeWrapper } from '@anm/components/errors/ErrorGoHome/Wrapper';

export type ErrorGoHomeProps = {
  title?: string;
  button?: Partial<ButtonProps>;
  className?: string;
  description?: string;
};

const defaultButtonProps: ButtonProps = {
  href: '/',
  size: 'large',
  variant: 'light-blue-lowercase'
};

const ErrorGoHome: FC<ErrorGoHomeProps> = ({
  button,
  className,
  description,
  title = 'Oops, something went wrong!'
}) => (
  <ErrorGoHomeWrapper {...{ title, className, description }} backgroundVariant="white-to-light-blue">
    <Button {...{ ...defaultButtonProps, ...button }}>Go Home</Button>
  </ErrorGoHomeWrapper>
);

export default ErrorGoHome;
