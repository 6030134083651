import Background, { BackgroundVariants } from '@anm/components/Background';
import Image from '@anm/components/image/Image';
import { FC } from 'react';

import ErrorWrapper, { TitleBlockError } from './Wrapper';

export type ErrorRobotProps = {
  title?: string;
  className?: string;
  description?: string;
  backgroundVariant: BackgroundVariants;
};

const ErrorRobot: FC<ErrorRobotProps> = ({
  title,
  children,
  className,
  description,
  backgroundVariant
}) => (
  <Background variant={backgroundVariant}>
    <ErrorWrapper className={className}>
      <div>
        <TitleBlockError
          size="super-large"
          title={title}
          description={description}
        />
        {children}
      </div>
      <Image src="images/robot.gif" />
    </ErrorWrapper>
  </Background>
);

export default ErrorRobot;
